import { For } from 'solid-js';
import { BaseAccordion, Button, Divider, SocialLinks } from '~/components/ui';
import { FOOTER_NAV_ITEMS, type FooterPart } from '~/data/footer';
import { t } from '~/libs/i18n/utils';
import { cn } from '~/utils';
import { FooterEmailForm } from './FooterEmailForm';

export function Footer() {
  return (
    <footer
      class="border-t border-slate-300 dark:border-neutral-700 mt-12 md:mt-16
        px-4"
    >
      <div
        class="mx-auto max-w-7xl flex flex-col gap-3 py-8 lg:flex-row-reverse
          md:justify-between md:pt-12"
      >
        <div class="flex flex-[2] flex-col gap-3 md:flex-row md:justify-around">
          {/* <Divider class="md:hidden" /> */}

          <div class="flex flex-1 gap-10 sm:gap-12 self-center pt-3 w-full">
            <ul class="flex-1 space-y-5">
              <For each={FOOTER_NAV_ITEMS.left}>
                {([i18nKey, url]) => (
                  <FooterListItem i18nKey={i18nKey} url={url} />
                )}
              </For>
            </ul>

            <ul class="flex-1 space-y-5">
              <For each={FOOTER_NAV_ITEMS.right}>
                {([i18nKey, url]) => (
                  <FooterListItem i18nKey={i18nKey} url={url} />
                )}
              </For>

              <FooterListItem
                i18nKey="layout:footer.nav.consentPreferences"
                url="#"
                linkClass="termly-display-preferences"
              />

              <FooterListAccordion
                i18nKey="layout:footer.nav.legal.title"
                items={FOOTER_NAV_ITEMS.legal}
              />
            </ul>
          </div>

          <div class="space-y-8 md:space-y-10">
            <SocialLinks shape="circle" action="follow" />
            <FooterEmailForm />
          </div>
        </div>

        <div class="flex flex-1 justify-center pt-4 md:pt-3">
          <div class="flex flex-col items-center gap-2 md:w-44 md:items-start">
            <span class="text-2xl font-bold md:mx-0 dark:text-white">
              DreamFlare
            </span>
            <p class="text-xs font-medium text-sky-500 dark:text-sky-300">
              {new Date().getFullYear()} © DreamFlare Inc.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

interface FooterListItemProps {
  i18nKey: string;
  url: string;
  linkClass?: string;
}

function FooterListItem(props: FooterListItemProps) {
  return (
    <li class="text-sm leading-none">
      <Button
        class={cn(
          'h-fit whitespace-nowrap p-0 font-bold dark:text-white',
          props.linkClass,
        )}
        variant="link"
        href={props.url}
      >
        {t(props.i18nKey)}
      </Button>
    </li>
  );
}

interface FooterListAccordionProps {
  i18nKey: string;
  items: FooterPart;
}

function FooterListAccordion(props: FooterListAccordionProps) {
  return (
    <BaseAccordion
      title={t(props.i18nKey)}
      headingClass="text-sm font-bold"
      icons={{
        wrapperClass: 'text-2xs ml-2',
        expandIcon: '▼',
        shrinkIcon: '▲',
      }}
    >
      <div class="space-y-2.5 w-fit mt-3">
        <Divider class="bg-slate-300" />
        <ul class="space-y-5 w-fit ">
          <For each={props.items}>
            {([i18nKey, url]) => (
              <FooterListItem
                linkClass="font-semibold"
                i18nKey={i18nKey}
                url={url}
              />
            )}
          </For>
          <p>
            <a href="http://www.playwire.com" rel="noopener" target="_blank">
              <img
                src="https://www.playwire.com/hubfs/Powered-by-Playwire-Badges/Ads-Powered-by-playwire-2021-standalone-small-300px.png"
                alt="Playwire"
                width="150"
                loading="lazy"
                style="width: 150px; display: block;"
              ></img>
            </a>
          </p>
        </ul>
      </div>
    </BaseAccordion>
  );
}
