import { link } from '~/utils';
import { EXTERNAL_LINKS } from './external-links';

export type FooterPart = [string, string][];
type FooterNavItems = {
  left: FooterPart;
  right: FooterPart;
  legal: FooterPart;
};

export const FOOTER_NAV_ITEMS: FooterNavItems = {
  left: [
    // ['Free this Week', link.toFreeThisWeek()],
    // ['Top 10 Shows', link.toCategory({ category: 'top_10' })],
    ['layout:footer.nav.popularNow', link.toCategory({ category: 'trending' })],
    [
      'layout:footer.nav.newReleases',
      link.toCategory({ category: 'new_releases' }),
    ],
    ['layout:footer.nav.becomeACreator', link.toStudiosLanding()],
    ['DFMP Studios', link.toStudiosLanding()],
    // ['Get Premium', link.toMembershipPage()],
    // ['Shop', link.toShop()],
  ],
  right: [
    ['layout:footer.nav.aboutUs', link.toStudiosLanding()],
    ['layout:footer.nav.investorsAndPress', EXTERNAL_LINKS.EMAIL_INFO_DF],
  ],
  legal: [
    ['layout:footer.nav.legal.privacyPolicy', link.toPrivacyPolicy()],
    ['layout:footer.nav.legal.termsOfService', link.toTermsOfService()],
    //['layout:footer.nav.legal.cookiePolicy', link.toCookiePolicy()],
    ['layout:footer.nav.legal.refundPolicy', link.toRefundPolicy()],
    ['layout:footer.nav.legal.dmcaPolicy', link.toDMCAPolicy()],
  ],
} as const;
